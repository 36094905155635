
@media (max-width:992px){
    .calendar-wrapper{
        margin-top: 80px;
    }

    /* .fc-toolbar > div {
        float: left !important;
        width: 100%;
    } */
    .fc-toolbar > div.fc-left, 
    .fc-toolbar > div.fc-center, 
    .fc-toolbar > div.fc-right {
        float: left !important;
        width: 100%;
    }



}



@media screen and (max-width: 786px) {
    .fc-toolbar-chunk{
        margin-bottom: 5px;
    }
        .fc .fc-toolbar{
        display: block;
    }
    .fc .fc-toolbar-title{
        font-size: 1rem;
    }  
    .fc .fc-button .fc-icon{
        font-size: 1rem;
    }
}

.fc-header-left, fc-header-center, fc-header-right {
    width: 100%;
    display: block;
}