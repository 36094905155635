.logo-block {
    height: 50px;
    background-image: linear-gradient(to top, #2160b5, #083a7b 93%);
    padding: 10px 11px 0px 9px;
}

.logo-block > img {
    width: 42px;
    height: 39px;
    margin-bottom: 28px;
}

.MuiListItem-button:hover{
    background-color: #1b5198 !important;
    color: white;
}