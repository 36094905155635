.event {
    border: 1px solid rgba(0,0,0,0.1);
    height: 139px;
    border-radius: 6px;
    background-color: #ffffff;
    float: left;
    margin-left: 8px;
    padding: 12px;
    max-width: 273px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.event p{
    margin-bottom: 0;
}