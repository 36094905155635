.typeContainer{
    display: inline-block;
    padding: 0 10px;
    border: 1px solid;
}

.typeNode{
    /* background-color: green;
    color: white;
    border-color: green; */
}

.typePump{
    /* background-color: gray;
    color: black;
    border-color: gray; */
}

