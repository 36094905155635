.stateContainer{
    display: inline-block;
    padding: 0 10px;
    border: 1px solid;
}

.stateOn{
    background-color: green;
    color: white;
    border-color: green;
}

.stateOff{
    background-color: gray;
    color: black;
    border-color: gray;
}

.stateError {
    background-color: red;
    border-color: red;
    color: white;
}

