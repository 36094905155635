.network-info-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:50px;
}

.network-info{
    max-width: 324px;
    min-width: 330px;
    /* margin: 0 auto; */
    height: 50px;
    display: flex;
    justify-content: center;
}

.indicator {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: gray;;
    display: inline-block;
    margin-right: 5px;
}

.network-info-item{
    display: flex;
    margin: 10px 10px 10px 10px;
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    align-self: center;
    align-items: center;
}

.indicator.online{
    background-color: green;;
}

.indicator.error{
    background-color: red;
}

@media(max-width:992px){
    .network-info{
        margin-left: 35px;
    }
}