.sidebar_show_button{
    position: fixed;
    top: 10px;
    left: 5px;
    display: none;
    z-index: 8;
    /* width: 50px; */
    /* height: 50px; */
    /* background-color: #2160b5; */
    /* border-radius: 50%; */
    /* cursor: pointer;*/
}


@media (max-width: 992px){
	.sidebar_show_button{
        display: block;
    }
}