.main_block{    
    margin: 16px 0 0 0;
    max-width: 100%;
    padding-top: 10px;
}

.main_block > div {
    padding: 0 20px 20px 20px;    
}

.groups_block{
    overflow: auto;
    height: 600px;
}

#map {
    width: 100%;
    min-height: 500px;
    background-color: #cfcfcf;
    height: 600px;
    max-height: 100%;
}