.user_block{
    margin: 0;
}

.alert_block > h4, .user_block > h4 {
    text-align: center;
}

.history_block > h4 {
    font-size: 20px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #263c58;
    margin-left: 10px;
    margin-bottom: 20px;
}

.alert_block > h4 > span, .user_block > h4 > span {
    display: block;
    font-size: 14px;
    color: #999;
    margin-top: 8px;
}

.alert_block>div>div, .user_block>div>div {
    background: #fff;
    margin: 10px;
    max-width: 46.666%;
    flex: 46.666% 1;
    padding: 0;
    box-shadow: 0 0 0 0.4px rgb(0 0 0 / 20%);
    display: inline-table;
}

.alert_block>div>div>div, .user_block>div>div>div {
    float: left;
    width: 49%;
    padding: 20px;
}

.alert_block>div>div>div>h6, .user_block>div>div>div>h6 {
    color: #999;
}

.alert_block>div>div>div>div, .user_block>div>div>div>div {
    display: block;
}

.user_block img {
    width: 28px;
    height: 28px;
}

.alert_block>div>div>div>div>div>*, .user_block>div>div>div>div>div>* {
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
}

.alert_block>div>div>div>div>div>div>span, .user_block>div>div>div>div>div>div>span {
    color: #999;
    font-size: 13px;
}

.alert_block>div>div>div>div>div>div>p, .user_block>div>div>div>div>div>div>p {
    font-size: 13px;
    line-height: 1;
    font-weight: 600;
}

.user_block .setting_user_block>.user_info>div {
    display: block;
    text-align: center;
    position: relative;
    color: #777;
}

.user_block .setting_user_block>.user_info>.user_image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #cfcfcf;
    display: block;
    margin: 0 auto;
}

.vertical_horizontal_center {
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
    transform: translate(-50%,-50%);
}

.alert_block .setting_user_block>.user_info>div>p, .user_block .setting_user_block>.user_info>div>p {
    margin-bottom: 0;
}

.alert_block .setting_user_block>.user_info>div>span, .user_block .setting_user_block>.user_info>div>span {
    font-size: 14px;
}

.alert_block>.row>div>.settins_block_bottom, .user_block>.row>div>.settins_block_bottom {
    width: 100%;
    border-top: 1px solid hsla(0,0%,80%,.47);
    background-color: rgba(55,55,55,.08);
    padding: 2px 10px;
}

.alert_block>div>div>div>div, .user_block>div>div>div>div {
    display: block;
}

.user_block>.row>div>.settins_block_bottom>.edit_btn {
    float: right;
    cursor: pointer;
}