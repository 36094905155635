.pump_block{
    height: 228px;
}
.pump_content {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;    
    text-align: center;
    margin: 0;
    margin: 5px 0 0 0;
    font-family: 'Open Sans', sans-serif;

}