.navigationArrow {
    position: absolute;
    cursor: pointer;
    width: 34px;
    height: 34px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border-radius: 50%;
    right: -16px;
    top: 50%;
    bottom: 50%;
    transform: translate(0, -50%);
}

.navigationArrow img{
    position: absolute;
    /* top: 5px; */
}

/* .right img{
    left: 5px;
} */

 .left{
    left: -16px;
    top: 50%;
    bottom: 50%;
    transform: translate(0, -50%);
    display: none;
}

.left img{
    width: 24px;
    height: 24px;
    display: block; 
    cursor: pointer;
    left: 3px;
    transform: rotate(180deg);
}

.navigationArrow button{
    width: 34px;
    height: 34px;
}