.pump{
    padding: 0;
    /* width: 87px; 
    height: 139px;
    margin-left: 11px; */
    float: left;
}

.pump > p {
    width: 80px;
    height: 19px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    margin-bottom: 8px;
    color: #263c58;
    display: block;
    margin: 0 auto;
}
.pump > .on_off {
    border-radius: 2px;
    border: 1px solid #21b52c;
    color: #21b52c;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    padding: 1px 7px;
}

.block-off{
    opacity: 0.5;
}

.block-off > .on_off {
    border: solid 1px rgb(38, 60, 88, 0.2);
    border-radius: 2px;
    border: solid 1px rgba(38, 60, 88, 0.2);
    color: rgba(38, 60, 88, 0.2);
}

.pump > .manomenter-block {
    position: relative;
    width: 140px;
    /* height: 60px; */
    display: block;
    margin: 0 auto;
    margin-top: 5px;
    margin-bottom: 5px;
    background: transparent;
}

.pump > .manomenter-block > img {
    position: absolute;
    vertical-align: middle;
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%,-50%);
    width: 60px;
    height: 60px;
}

.pump > .number {
    color: #fff;
    font-weight: bold;
    padding: 3px 10px;
    border-radius: 2px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.pump .green{
    background-color: #8dc63f;
}

.pump .MuiSpeedDial-root .MuiSpeedDial-fab {
    width: 36px;
    height: 36px;
    background-color: #2160b5;
}

.pump .MuiSpeedDial-actions{
     padding-top: 36px !important; 
}

.pump .MuiSpeedDialAction-fab {
    width: 30px;
    height: 30px;
    min-height: 30px;
    margin: 4px;
}