.schedule {
    padding: 20px 20px;
    height: 228px;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    margin-right: 8px;
    width: auto !important;
    flex: auto !important;
}

 .leftSide img{
    width: 24px;
    height: 24px;
}
.leftSide span{
    margin-left: 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.13px;
    text-align: left;
    color: #263c58;
}



.schedule-list-block{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    transition: 0.14555s;
}

.schedule-list-block > .event:first-child{
    margin-left: 0;
}

.schedule_content {
    position: relative;
    display: block;
    margin-top: 17px;
    height: 100%;
    width: 100%;
}

.schedule_content > .carusel_block {
    position: relative;
    height: 100%;
    width: 100%;
    display: block;
    overflow: hidden;
}
