.schedule-mobile-wrapper {
    position: fixed;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 48px;
    width: 100%;
    padding: 0 !important;
}

.schedule-mobile-wrapper .card{
    margin-right: 0;
}