.side-floating-buttons{
    position: fixed;
    width: 56px;
    height: 124px;
    right: 10px;
    z-index: 2;
    top: calc(50% - 62px);
}

.side-floating-buttons > :first-child{
    margin-bottom: 10px;
}
.pump-button{
    position: relative;
}
.pump-indicator{
    position: absolute;
    background-color: #8dc63f;
    width: 14px;
    height: 14px;
    left: 0px;
    top: 0px;
    border-radius: 50%;   
    padding: 5px;
    line-height: .5;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;

}