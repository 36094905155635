.event {
    border: 1px solid rgba(0,0,0,0.1);
    height: 139px;
    border-radius: 6px;
    background-color: #ffffff;
    float: left;
    margin-left: 8px;
    padding: 12px;
    max-width: 273px;
    position: relative;
}

.event p{
    margin-bottom: 0;
}

.event > p:first-child, .event >span {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    margin-top: 0;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #263c58;
    margin-bottom: 8px;
}
.event > p > .folder {
    background-color: #ffab38;
    width: 12px;
    height: 10px;
    border-radius: 2px;
    border-top-left-radius: 0;
    position: relative;
}
.event > p > .folder::after {
    content: "";
    position: absolute;
    width: 55%;
    height: 2px;
    left: 0;
    top: -2px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: #ffab00;
}
.event > p > span {
    margin-right: 12px;
    display: inline-block;
}
.event > p > .green,
.event > p > .yellow {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #21b52c;
}
.event div{
    width: 100%;
}
.event > .block-text {
    height: 30px;
    border-radius: 4px;
    background-color: rgba(33, 96, 181, 0.05);
    padding: 5px;
}

.event > div > p {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    color: #263c58;
    text-overflow: ellipsis;
}

.event > div.block_start_stop {
    display: none;
}

.event .MuiSpeedDial-root .MuiSpeedDial-fab{
    width: 36px;
    height: 36px;
    background-color:#2160b5;
}

.event .MuiSpeedDial-actions{
    padding-right: 36px !important;
}

.event .MuiSpeedDialAction-fab {
    width: 30px;
    height: 30px;
    min-height: 30px;
    margin: 4px;
}

.event.stoped::before{
    content: "";
    border-radius: 6px;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #9e9ea0bf;
}

.weekDay{
    display: inline-block;
    background-color: #2160b5;
    color:white;
    margin-right: 8px;
    text-align: center;
    padding: 3px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}