.MuiDialogContent-root button:focus, .MuiPickersBasePicker-container button:focus{
    outline: none;
}

.MuiPickersToolbar-toolbar,.MuiPickerDTTabs-tabs,.MuiPickersDay-daySelected,.MuiPickersDay-daySelected:hover{
    background-color: #2160b5 !important;
}

.MuiFormControl-marginNormal{
    width: 100%;
}