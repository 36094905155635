.group-mobile-wrapper {
    position: fixed;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 48px;
    width: 100%;
    /* padding: 10px; */
    padding-bottom:20px ;
    background-color: white;
    overflow: auto;
    max-height: 400px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.31);
}

