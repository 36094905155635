.top-blocks{
    margin-right: 0;
    margin-left: 0;
}

.top-blocks > .pump_block{
    margin-right: 8px;
}

.top-blocks .card:hover{
	box-shadow:0 0px 5px 2px rgba(0, 0, 0, 0.1);
	background-color: rgba(244, 245, 247, 50);
}

.top-blocks .card:hover .event{
	background-color: rgba(244, 245, 247, 50);

}