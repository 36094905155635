
.groups {
    border-radius: 4px;
    padding: 0 0;
    margin-top: 10px;
    border: solid 1px;
}

.groups:first-child {
    margin-top: 0;
}

 .groups > .groups_head {
    padding: 14px 0;
    cursor: pointer;
    transition: .5s;
}

.groups > div > div {
    display: inline-block;
}

.groups_head > .icon_name > .icon{
    width: 14px;
    height: 10.8px;
    display: inline-block;
    border-radius: 2px;
    border-top-left-radius: 0;
    position: relative;
    margin-left: 13px;
    margin-right: 7.3px;
}

 .groups_head > .icon_name >  .icon:after {
    content: "";
    position: absolute;
    width: 55%;
    height: 2px;
    left: 0;
    top: -2px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.name {
    position: relative;
    float: right;
}

.icon_name > .name > p {
    line-height: 1.4;
    margin-bottom: 0;
}

.groups > .groups_head > .all_wather {
    margin-right: 12px;
}


.on {
    opacity: 1;
}

.off {
    opacity: .4;
}

.group-head .icon_name{
    line-height: 2;
    height: 100%;
}

.group-head .icon_name .icon_back{
    margin-left: -10px !important;
    margin-top: 10px;
    margin-right: 15px !important;
    display: inline-block;
}

.group_list >.group> div {
    display: inline-block;
}

.group_list >.group {
    width: 100%;
    cursor: pointer;
    padding: 5px 0;
}

.group_list >.group> .icon_name {
    padding-left: 23px;
}

.group_list >.group> .icon_name > .icon {
    width: 8px;
    height: 8px;
    border-radius: 20px;
    display: inline-block;
    margin-right: 6px;
    margin-bottom: 1px;
}

.group_list >.group> .all_wather {
    margin-right: 12px;
    float: right;
    opacity: .6;
}


.group-head{
    height: 60px;
    /* margin-left: -10px;
    margin-top: -10px; */
    height: 60px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.w20{
    width: 20%;
}
.w80 {
    width: 80%;
}