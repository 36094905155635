body button:focus{
	outline: none !important;
}
.content {
    margin-left: 0;
    margin-top: 16px;
    /* flex: 92.866667% !important; */
    /* max-width: 95.866667% !important; */
	padding-left: 75px;
	display: block;
	float: left;	
}


.card{	
	border-radius: 6px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	background-color: #ffffff;
	/* margin-right: 8px; */
	padding: 10px 14px 10px 14px;
	width: auto !important;
	flex: auto !important;	
	transition: all .2s;
}



@media (max-width: 992px){
	.content{
		padding: 0px;
		margin: 0;
	}

	.content .main_block{
		margin:0px;
		padding: 0px;
	}

	.content .main_block.card{
		border-radius: 0;
		box-shadow: none;
		border:none
	}

	.content .main_block > .row
	{
		padding: 0px;
	}

	.content .main_block .map_block{
		padding: 0px;
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		height: calc(100% - 56px);
		width: 100%;
		z-index: 1;		
	}

	#map{
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		height: 100%;
	}

	/* Google map button ovveride*/
	.gmnoprint .gm-style-mtc:first-child {
		margin-left: 70px;
	}

}