.MuiBottomNavigation-root{
    -webkit-box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.31);
    -moz-box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.31);
    box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.31);
}

.MuiBottomNavigationAction-root{
    max-width: 50% !important;
}

.MuiBottomNavigationAction-root:focus{
    outline: none;
}
.MuiBottomNavigationAction-root.Mui-selected{
    color:#2160b5 !important;
}