.wateringinfotext {}


.bordered{
    border: 1px solid #C0C5BF;
    border-radius: 4px;    
    padding: 5px 10px;     
    margin-bottom: 8px;
}

.current-state-window, .edit-state-window, .manual-start-state {
    padding: 11px 4px 11px 4px;
    display: flex;
    flex-direction: column;
}

.btn-edit-container {
    display: flex;
    justify-content: end;
}

.area-info-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
}

.area, .path {
    margin: 0 10px 0 10px;
}

.text-info {
    display: block;
    color: black !important; 
    max-width: 204px;
}

.watering-info-container {
    display: flex;
    flex-direction: row;
}

.next-watering-info-title {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
}

.next-watering-plan {
    display: flex;
    flex-direction: row;
}

.next-watering-plan span {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.04em;
    color: #3f3f3f;
    margin-left: 5px;
}

.vertical-divider {
    width: 1px;
    background-color: black;
    margin: 0 5px;
}

.device-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.action-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.manual-start-state-title{
    text-align: center;
    margin: 5px 0;
    font-size: 20px;
}

.manual-start-state-duration{
    display: flex;
    justify-content: space-around;
    margin: 5px 0;
}

.manual-start-state-action{
    display: flex;
    justify-content: space-around;
    margin-top: 20  px;    
    gap: 10px;
}

@media only screen and (max-width: 992px) {
    .text-info{
        max-width: 100%;
        font-size: 13px;
    }

    .device-info{
        font-size: 13px;
        max-width: 362px;
    }

    .area-info-container{
        margin-bottom: 8px;
        font-size: 13px;
        max-width: 362px;
    }

}

@media only screen and (max-width: 540px) {

    .device-info{
        flex-direction: column;
    }

    .area-info-container{
        flex-direction: column;
    }

}