
.wather_block {
    height: 228px;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    margin-right: 0px;
    padding: 35px 14px 20px 14px;
    width: auto !important;
    flex: auto !important;
}
.wather_block > .wather_top {
    height: 31px;
}

.wather_block > .wather_top > div {
    float: left;
    width: auto;
    height: 31px;
}
.wather_top_left > * {
    display: inline-block;
}
.wather_top_left > img {
    margin-right: 13px;
    width: 42px;
    height: 30px;
}
.wather_top_left > span {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: 0.15px;
    text-align: left;
    vertical-align: text-top;
    color: #263c58;
}

.wather_block > .wather_top > div {
    float: left;
    width: auto;
    height: 31px;
}

.wather_block > .wather_top > div:nth-child(2) {
    float: right;
}

.wather_top_right > p {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.9;
    letter-spacing: 0.15px;
    text-align: right;
    color: #263c58;
    margin: 0;
}

.wather_content {
    margin-top: 34px;
}

.wather_content > div {
    margin-bottom: 12px;
}

.wather_content > div > div {
    display: inline-block;
    width: auto;
    height: 24px;
}

.wather_content > div > div:nth-child(2) {
    float: right;
}

.wather_content > div > div > img {
    margin-right: 8px;
    vertical-align: top;
}

.wather_content > div > div > span {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: 0.12px;
    text-align: left;
    color: #263c58;
    height: 24px;
}

.wather_content > div > div > p {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.13px;
    text-align: right;
    color: #263c58;
}

.wather_footer {
    padding: 10px 10px;
    border-top: 1px solid rgba(0,0,0,0.1);
}

.wather_footer > div {
    display: inline-block;
    text-align: center;
    width: 31.13333%;
}

.wather_footer > div>* {
    display: block;
}

.wather_footer > div> p {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: 0.13px;
    text-align: center;
    color: #263c58;
    
    margin-bottom: 0;
}

.wather_footer > div> span {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: 0.12px;
    text-align: center;
    color: #263c58;
    margin-top: 5px;
}
.wather_footer > div> span > span {
    color: rgba(38, 60, 88, 0.6);
}

* {
    margin: 0;
    padding: 0;
}


.center-content{
    justify-content: center;
    align-items: center;
}