
.Sign-in-block{
	position: relative;
	width: 360.2px;
	left: 50%;
	right: 50%;
	transform: translate(-50%,125.2px);
	bottom: auto;
}
.logo-full-block{
	height: 83.8px;
	display: block;
		margin: 0 auto;
}
.logo-full-block > img{
	width: 100%;
	height: 100%;
}
.Sign-in-block > h4{
	margin-top: 41px;
	margin-bottom: 0;
	color: #263c58;
	font-size: 22px;
}
.Sign-in-form{
	margin-top: 30px;
}
.Sign-in-form > label{
	  width: 36px;
  line-height: 19px;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #263c58;
  text-align: left;
}

.Sign-in-form > input, .Sign-in-form > button {
	margin: 0 auto 30px auto;
	display: block;
	width: 100%;
  height: 42px;
  border-radius: 4px;
  border: solid 1px rgba(30, 42, 70, 0.2);
  background-color: #ffffff;
  font-size: 14px;
  line-height: 19px;
  padding: 11px 16px;
}

.Sign-in-form > input[type=submit]{
	background-color: rgba(33,96,181,0.2);
	  font-size: 48px;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.Sign_submit_active{
	background-color: #2160b5 !important;
	transition: .3s;
}

.btn-submit{
    background-color: rgba(33,96,181,0.2);
    font-size: 48px;
    font-family: OpenSans;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}



@media (max-width: 378px){

  .Sign-in-block{
    width: 90%;
  }

}